// This handles the basic functionality of submitting the forms, and closing the modal or showing an error message.
// Additional logic (updates to the DOM etc) can be added to a separate ajax:success event listener on a case-by-case basis
const handleModalAjaxForm = ($formElement) => {
  const $parentModal = $formElement.closest('.iziModal')
  $formElement.on('ajax:success', () => $parentModal.iziModal('close')).on('ajax:error', ajaxErrorMessage)
}

// Show error message if present, otherwise just show the returned HTTP status
// const ajaxErrorMessage = ({ detail, responseText }) => {
const ajaxErrorMessage = ({ detail }) => {
  const response = detail[0]
  let errorString = Array.isArray(response) ? response.join('\n') : response

  if (errorString.length === 0) {
    const { status, statusText } = detail[2]
    errorString = `${status} ${statusText}`
  }

  alert(`${I18n.saving_failed}\n${errorString}`)
}

// Create the HTML dynamically from an array of error messages
const createErrorHTML = (errors) => {
  if (!errors?.length) {
    errors = [I18n.unknown_error]
  }

  const template = document.createElement('template')
  template.innerHTML = `
    <div class="form-errors">
      <h2 class="mb-2">${I18n.saving_failed}</h2>
      <ul></ul>
    </div>
  `
  const content = template.content
  const ul = content.querySelector('ul')

  for (const msg of errors) {
    const li = document.createElement('li')
    li.innerText = msg
    li.classList.add('whitespace-pre-line')
    ul.appendChild(li)
  }

  return document.importNode(content, true)
}

// Callback function for ajax forms that renders an HTML error box from pre-rendered HTML (shared/_form_errors.html.erb) or from an array of error strings
// Prepends the errors to the top of the form / specified container (removes existing errors first if present)
const renderFormErrors = (errors, targetContainer) => {
  targetContainer ||= document.querySelector('main')
  targetContainer.querySelector('.form-errors')?.remove()

  if (typeof errors === 'string') {
    // Pre-rendered HTML from the server should start with an HTML tag (first non-blank character being "<")
    // Otherwise, it's most likely an uncaught server error that shouldn't be shown to users
    if (errors.match(/\S{1}/)?.at(0) !== '<') {
      targetContainer.prepend(createErrorHTML([]))
    } else {
      targetContainer.insertAdjacentHTML('afterbegin', errors)
    }
  } else {
    // `errors` should be an array of error messages if it isn't a string
    targetContainer.prepend(createErrorHTML(errors))
  }

  targetContainer.scrollIntoView({ behavior: 'smooth' })
}

export { handleModalAjaxForm, ajaxErrorMessage, renderFormErrors }
